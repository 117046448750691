import { QueryFilter } from "@models/api-request.model";
import { Exportable, Exporter } from "@models/index";

export class EquipmentCategory implements Exportable {


  id:        number = 0;
  version:   number | undefined;
  name:      string | undefined;
  color:     string | undefined;

  constructor(){}

}

export class EquipmentCategoryFilter implements QueryFilter {

  name:      string | undefined;

}


export class EquipmentCategoryExporter implements Exporter  {

  name;


  constructor() {

    this.name= {
      nameToShow: 'Nombre'
    };
  }
}




